import { FC } from "react";

import { useTranslation } from "react-i18next";

import { useGroupsStore } from "../../store";
import { useDisplayDataStore } from "../../store/displayData";
import { EntityGroupType } from "../../types";

const DisplayControls: FC = () => {
  const { t } = useTranslation();
  const { data, setData } = useDisplayDataStore();
  const { xmlData } = useGroupsStore();

  return (
    <div>
      {t("panels:displayData")}
      {xmlData.mandible && (
        <button
          className={data.mandible ? "active" : "inactive"}
          onPointerDown={() => setData({ mandible: !data.mandible })}
        >
          {EntityGroupType.MANDIBLE}
        </button>
      )}
      {xmlData.maxilla && (
        <button
          className={data.maxilla ? "active" : "inactive"}
          onPointerDown={() => setData({ maxilla: !data.maxilla })}
        >
          {EntityGroupType.MAXILLA}
        </button>
      )}
    </div>
  );
};

export default DisplayControls;
