import { OpacityEntity, Object3DMeasures } from "../types";
import { createStore } from "./createStore";

export type EntitiesStore = {
  opacityEntities: OpacityEntity[];
  selected: string | null;
  selectedMeasures: Object3DMeasures;
  setOpacityEntities: (data: OpacityEntity[], append?: boolean) => void;
  updateOpacityEntity: (entityKey: string, opacity: number) => void;
  removeOpacityEntity: (entityKey: string) => void;
  toggleAllEntities: () => void;
  removeAllEntities: () => void;
  getAllHidden: () => boolean;
  setSelectedEntity: (fileName: string) => void;
  setSelectedMeasures: (measures: Object3DMeasures) => void;
};

const areAllHidden = (entities: OpacityEntity[]): boolean => {
  for (const entity of entities) {
    if (entity.opacity > 0) {
      return false;
    }
  }

  return true;
};

export const useEntitiesStore = createStore<EntitiesStore>("EntitiesStore", (set, get) => ({
  opacityEntities: [],
  selected: null,
  selectedMeasures: null,
  getAllHidden: () => areAllHidden(get().opacityEntities),
  setOpacityEntities: (data, append) =>
    set(state => ({
      opacityEntities: !append ? data : state.opacityEntities.concat(data),
    })),
  updateOpacityEntity: (entityKey, opacity) => {
    const entity = get().opacityEntities.find(entity => entityKey === entity.url);

    set(state => ({
      opacityEntities: state.opacityEntities.map(entity => {
        if (entityKey === entity.url) {
          entity.opacity = +opacity.toPrecision(1);
        }
        return entity;
      }),
      selected: entity ? entity.name : state.selected,
    }));
  },
  removeOpacityEntity: entityKey => {
    const entity = get().opacityEntities.find(entity => entityKey === entity.url);

    set(state => ({
      opacityEntities: state.opacityEntities.filter(entity => {
        if (entityKey === entity.url) {
          return false;
        }
        return true;
      }),
      selected: entity && state.selected === entity.name ? null : state.selected,
    }));
  },
  toggleAllEntities: () =>
    set(state => {
      const allHidden = areAllHidden(get().opacityEntities);

      return {
        opacityEntities: state.opacityEntities.map(entity => ({
          ...entity,
          opacity: allHidden ? 1 : 0,
        })),
      };
    }),
  removeAllEntities: () =>
    set(() => ({
      opacityEntities: [],
      selected: null,
    })),
  setSelectedEntity: fileName => set(() => ({ selected: fileName })),
  setSelectedMeasures: measures => set(() => ({ selectedMeasures: measures })),
}));
