import { FC } from "react";

import { useCameraStore } from "../../store/camera";
import { CameraType } from "../../types";

import "./CameraViewControls.scss";

const CameraViewControls: FC = () => {
  const { cameraType, setCameraType } = useCameraStore();

  const onClick = (event: React.MouseEvent<HTMLButtonElement>, type: CameraType) => {
    event.stopPropagation();

    setCameraType(type);
  };

  return (
    <div className="cameraViewControls">
      {cameraType === CameraType.Perspective ? (
        <button onPointerDown={e => onClick(e, CameraType.Orthographic)}>{CameraType.Perspective}</button>
      ) : (
        <button onPointerDown={e => onClick(e, CameraType.Perspective)}>{CameraType.Orthographic}</button>
      )}
    </div>
  );
};

export default CameraViewControls;
