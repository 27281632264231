import { FC, Suspense, useRef } from "react";

import { GizmoHelper, GizmoViewport } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { useLocation } from "react-router";
import * as THREE from "three";

import { Progress } from "../";
import { SCENE_CONFIG } from "../../constants";
import useHandleCanvasCursor from "../../hooks/useHandleCanvasCursor";
import RotationWidget from "../RotationWidget/RotationWidget";
import { Controls, ModelPositioner, DimensionHelpers, SceneLight } from "./components";
import SceneCamera from "./components/SceneCamera";

const MainScene: FC = ({ children }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const location = useLocation();

  useHandleCanvasCursor(canvasRef);

  return (
    <Canvas
      ref={canvasRef}
      className="main-scene"
      dpr={window.devicePixelRatio}
      gl={{ antialias: false }}
      onCreated={({ gl }) => {
        gl.toneMapping = THREE.ACESFilmicToneMapping;
        gl.outputEncoding = THREE.LinearEncoding;
      }}
    >
      <Controls />
      {SCENE_CONFIG.GIZMO_ROUTES.includes(location.pathname) ? (
        <GizmoHelper alignment="bottom-left" margin={SCENE_CONFIG.GIZMO_MARGINS}>
          <GizmoViewport />
        </GizmoHelper>
      ) : (
        /** TODO: Implement the widget on Opacity page */
        <RotationWidget />
      )}
      <Suspense fallback={<Progress />}>
        <ModelPositioner>{children}</ModelPositioner>
      </Suspense>
      <DimensionHelpers size={SCENE_CONFIG.AXES_HELPER_LENGTH} />
      <SceneLight
        lightDistance={SCENE_CONFIG.LIGHT_DISTANCE}
        ambient={0.2}
        left={0.3}
        right={0.3}
        front={0.7}
        back={0.7}
        bottom={0.15}
        top={0.15}
      />
      <SceneCamera />
    </Canvas>
  );
};

export default MainScene;
