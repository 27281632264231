import { FC, useEffect, useRef } from "react";

import { useLoader, ThreeEvent } from "@react-three/fiber";
import { Color, Group } from "three";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";

import { MATERIAL_CONFIG, GROUP_ROTATION } from "../../../constants";
import { useHandleBoundingBox } from "../../../hooks";
import { VisibilityComponentProps } from "../../../types";

const STLRenderer: FC<VisibilityComponentProps> = ({
  url,
  name,
  isVisible,
  componentType,
  groupType,
  color,
  opacity = 1,
  setSelected,
}) => {
  const groupRef = useRef<Group>(null!);

  const object = useLoader(STLLoader, url);

  useHandleBoundingBox(groupRef, isVisible);

  useEffect(() => {
    object.computeVertexNormals();
  }, []);

  const handleFileSelection = (e: ThreeEvent<MouseEvent>): void => {
    e.stopPropagation();
    setSelected && setSelected(name);
  };

  /**  isVisible hides models that are not related to current Stepper view in Group view */
  if (!isVisible) {
    return null;
  }

  return (
    <group
      ref={groupRef}
      name={name}
      quaternion={GROUP_ROTATION}
      onPointerDown={handleFileSelection}
      userData={{ componentType, groupType }}
    >
      <mesh geometry={object}>
        <meshPhongMaterial
          side={2}
          refractionRatio={0}
          reflectivity={0}
          fog={false}
          flatShading={false}
          shininess={MATERIAL_CONFIG.SHININESS}
          specular={new Color(MATERIAL_CONFIG.SPECULAR_COLOR)}
          color={new Color(color ? color : MATERIAL_CONFIG.COLOR)}
          transparent={opacity !== 1}
          depthWrite={opacity === 1}
          opacity={opacity}
        />
      </mesh>
    </group>
  );
};

export default STLRenderer;
