import { FC, useState } from "react";

import { useTranslation } from "react-i18next";

import DisplayControls from "./DisplayControls";

import "./ControlPanel.scss";

const ControlPanel: FC = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  return (
    <div className="controlPanel">
      <button className="active" onPointerDown={() => setVisible(prev => !prev)}>
        {t("panels:controlPanel")}
      </button>
      {visible && <DisplayControls />}
    </div>
  );
};

export default ControlPanel;
