import { FC, useEffect } from "react";

import { MainScene } from "../";
import { useObject3DMeasures } from "../../hooks";
import { useEntitiesStore, EntitiesStore } from "../../store";
import { EntityType } from "../../types";
import { colorAssigner } from "../../utils";
import { ObjRenderer, PlyRenderer, StlRenderer } from "./components";

const entitiesSelector = (state: EntitiesStore) => state.opacityEntities;
const selectedMeasuresActionSelector = (state: EntitiesStore) => state.setSelectedMeasures;
const setSelectedActionSelector = (state: EntitiesStore) => state.setSelectedEntity;

const StoreDataConnector: FC = ({ children }) => {
  const setSelectedMeasures = useEntitiesStore(selectedMeasuresActionSelector);
  /** The below handles file selection from file panel */
  const result = useObject3DMeasures();

  useEffect(() => {
    setSelectedMeasures(result);
  }, [result]);

  return <>{children}</>;
};

const OpacityEntityRenderer: FC = () => {
  const currentFiles = useEntitiesStore(entitiesSelector);
  const setSelected = useEntitiesStore(setSelectedActionSelector);

  return (
    <MainScene>
      <StoreDataConnector>
        {currentFiles.map(({ type, ...entity }, index) => {
          const color = colorAssigner.assign(entity.url);

          if (type === EntityType.OBJ) {
            return <ObjRenderer key={index} {...entity} isVisible color={color} setSelected={setSelected} />;
          }

          if (type === EntityType.PLY) {
            return <PlyRenderer key={index} {...entity} isVisible color={color} setSelected={setSelected} />;
          }

          if (type === EntityType.STL) {
            return <StlRenderer key={index} {...entity} isVisible color={color} setSelected={setSelected} />;
          }
        })}
      </StoreDataConnector>
    </MainScene>
  );
};

export default OpacityEntityRenderer;
