import { FC } from "react";

import { MainScene } from "../";
import { GINGIVA_COLOR, TOOTH_COLOR } from "../../constants";
import { useGroupsStore, GroupsStore, useOrientationStore } from "../../store";
import { EntityComponentType, EntityGroupType, EntityType, Orientation } from "../../types";
import { ObjRenderer, PlyRenderer, StlRenderer } from "./components";
import DataDisplay from "./components/DataDisplay";

const groupsSelector = (state: GroupsStore) => state.entityGroups;
const xmlDataSelector = (state: GroupsStore) => state.xmlData;

type Props = {
  currentGroupIndex: number;
};

const getModelOpacityByOrientation = (groupType: EntityGroupType, orientation: Orientation) =>
  (orientation === "up" && groupType === EntityGroupType.MANDIBLE) ||
  (orientation === "down" && groupType === EntityGroupType.MAXILLA)
    ? 0
    : 1;

const getModelColorByType = (componentType: EntityComponentType) =>
  componentType === EntityComponentType.GINGIVA ? GINGIVA_COLOR : TOOTH_COLOR;

const GroupEntityRenderer: FC<Props> = ({ currentGroupIndex }) => {
  const entityGroups = useGroupsStore(groupsSelector);
  const xmlData = useGroupsStore(xmlDataSelector);

  const { cameraOrientation } = useOrientationStore();

  return (
    <MainScene>
      {Object.keys(entityGroups).map((key, index) => {
        const numericKey = parseInt(key);
        if (isNaN(numericKey)) {
          return null;
        }

        const group = entityGroups[numericKey];
        const isVisible = currentGroupIndex === index;

        if (group.every(g => g.type === EntityType.OBJ)) {
          return group.map((g, gIndex) => {
            return (
              <ObjRenderer
                key={`${index}-${gIndex}`}
                url={g.url}
                name={g.name}
                isVisible={isVisible}
                componentType={g.componentType}
                groupType={g.groupType}
                color={getModelColorByType(g.componentType)}
                opacity={getModelOpacityByOrientation(g.groupType, cameraOrientation)}
              />
            );
          });
        }

        if (group.every(g => g.type === EntityType.PLY)) {
          return group.map((g, gIndex) => {
            return (
              <PlyRenderer
                key={`${index}-${gIndex}`}
                url={g.url}
                name={g.name}
                isVisible={isVisible}
                componentType={g.componentType}
                groupType={g.groupType}
                color={getModelColorByType(g.componentType)}
                opacity={getModelOpacityByOrientation(g.groupType, cameraOrientation)}
              />
            );
          });
        }

        if (group.every(g => g.type === EntityType.STL)) {
          return group.map((g, gIndex) => {
            return (
              <StlRenderer
                key={`${index}-${gIndex}`}
                url={g.url}
                name={g.name}
                isVisible={isVisible}
                componentType={g.componentType}
                groupType={g.groupType}
                color={getModelColorByType(g.componentType)}
                opacity={getModelOpacityByOrientation(g.groupType, cameraOrientation)}
              />
            );
          });
        }

        return null;
      })}
      <DataDisplay xmlData={xmlData} currentGroupIndex={currentGroupIndex} />
    </MainScene>
  );
};

export default GroupEntityRenderer;
