import { FC, useEffect } from "react";

import { CameraViewControls, ControlPanel, GroupEntityRenderer, Stepper, useStepperContext } from "../components";
import useURLQueryParams from "../hooks/useURLQueryParams";
import { getModels } from "../services/groupModels";
import { useGroupsStore, GroupsStore } from "../store";
import PageLayout from "./layout/PageLayout";

const groupsActionSelector = (state: GroupsStore) => state.setEntityGroups;
const xmlDataActionSelector = (state: GroupsStore) => state.setXmlData;

const GroupPage: FC = () => {
  const { groupId } = useURLQueryParams();
  const { stepIndex, setStepIndex, totalSteps, setTotalSteps } = useStepperContext();

  const setEntityGroups = useGroupsStore(groupsActionSelector);
  const setXmlData = useGroupsStore(xmlDataActionSelector);

  useEffect(() => {
    groupId &&
      (async () => {
        const { groups, currentGroup, xmlData } = await getModels(groupId);
        setEntityGroups(groups);
        setTotalSteps(Object.keys(groups).length);
        setStepIndex(currentGroup);
        setXmlData(xmlData);
      })();
  }, [groupId]);

  return (
    <PageLayout>
      <>
        <GroupEntityRenderer currentGroupIndex={stepIndex} />
        <ControlPanel />
        <CameraViewControls />
        {totalSteps > 1 ? <Stepper /> : null}
      </>
    </PageLayout>
  );
};

export default GroupPage;
