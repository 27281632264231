import { FC, useEffect } from "react";

import { useTranslation } from "react-i18next";

import {
  Panel,
  ViewControls,
  OpacityPanel,
  MeasurementPanel,
  OpacityEntityRenderer,
  CameraViewControls,
} from "../components";
import useURLQueryParams from "../hooks/useURLQueryParams";
import { getModels } from "../services/opacityModels";
import { useEntitiesStore, EntitiesStore } from "../store";
import PageLayout from "./layout/PageLayout";

import "./OpacityControlPage.scss";

const entitiesActionSelector = (state: EntitiesStore) => state.setOpacityEntities;

const OpacityControlPage: FC = () => {
  const { files } = useURLQueryParams();
  const { t } = useTranslation();
  const setOpacityEntities = useEntitiesStore(entitiesActionSelector);

  useEffect(() => {
    files &&
      (async () => {
        const { entities } = await getModels(files);

        setOpacityEntities(entities);
      })();
  }, [files]);

  return (
    <PageLayout>
      <div className="panels">
        <div className="panels__header">{t("panels:header")}</div>
        <ViewControls />
        <Panel title={t("panels:file")} toggleBtn={t("panel:toggleBtn")} defaultCollapsed>
          <OpacityPanel />
        </Panel>
        <Panel title={t("panels:measurement")} toggleBtn={t("panel:toggleBtn")} defaultCollapsed>
          <MeasurementPanel />
        </Panel>
      </div>

      <OpacityEntityRenderer />
      <CameraViewControls />
    </PageLayout>
  );
};

export default OpacityControlPage;
